export const COLORSold = {
  black: {value: "#2a2a2a", label: "Schwarz"},
  darkBlue: {value: "#1F295C", label: "Dunkelblau"},
  lightBlue: {value: "#1B70B8", label: "Hellblau"},
  gray: {value: "#BFBEBE", label: "Grau"},
  red: {value: "#ED2124", label: "Rot"},
  white: {value: "#FEFEFE", label: "Weiß"},
  green: {value: "#55823B", label: "Grün"},
  brown: {value: "#843E1C", label: "Braun"},
  blackProtective: {value: "#191919", label: "Schwarzer Schutz"},
  whiteProtective: {value: "#FAFAFA", label: "Weißer Schutz"},
  grayProtective: {value: "#BBBBBB", label: "Grauer Schutz"},
};

export const COLORS = {
  white: {value: "#FEFEFE", label: "White - 002"},
  black: {value: "#111111", label: "Black - 506"},
  paleGrey: {value: "#bbc5cd", label: "Pale Grey - 178"},
  moleGrey: {value: "#6a6d77", label: "Mole Grey - 294"},
  convoyGrey: {value: "#47535b", label: "Convoy Grey- 354"},
  red: {value: "#db1d21", label: "Red - 531"},
  kellyGreen: {value: "#066d3f", label: "Kelly Green - 388"},
  royalBox: {value: "#09529b", label: "Royal Box - 262"},
  marine: {value: "#001d2e", label: "Marine - 309"},
  blackProtective: {value: "#191919", label: "Black Protective"},
  whiteProtective: {value: "#FAFAFA", label: "White Protective"},
  grayProtective: {value: "#BBBBBB", label: "Gray Protective"},
};

// export const COLORS = {
//   black: {value: "#2a2a2a", label: "Black"},
//   darkBlue: {value: "#1F295C", label: "Dark Blue"},
//   lightBlue: {value: "#1B70B8", label: "Light Blue"},
//   gray: {value: "#BFBEBE", label: "Gray"},
//   red: {value: "#ED2124", label: "Red"},
//   white: {value: "#FAFAFA", label: "White"},
//   green: {value: "#55823B", label: "Green"},
//   brown: {value: "#843E1C", label: "Brown"},
//   blackProtective: {value: "#191919", label: "Black Protective"},
//   whiteProtective: {value: "#FAFAFA", label: "White Protective"},
//   grayProtective: {value: "#BBBBBB", label: "Gray Protective"},
// };
