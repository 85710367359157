import React from "react";

import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";

import Link from "../components/Link";

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    justifyContent: "center",
  },
  cardLink: {flexGrow: 1},
  cardActions: {flexGrow: 1},
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  logo: {
    maxWidth: 250,
  },
  headerContent: {
    justify: "space-between",
    spacing: 24,
    align: "right",
  },
}));

export default function Categories({children}) {
  const classes = useStyles();

  return (
    <Container className={classes.cardGrid} maxWidth="lg">
      <Grid
        style={{
          justifyContent: "center",
        }}
        container
        spacing={4}
      >
        {children.map((card) => (
          <Grid item key={card.id} xs={12} sm={6} md={4}>
            <Link to={card.link} className={classes.cardLink}>
              <Card className={classes.card}>
                {card.preview}
                <CardContent className={classes.cardContent}>
                  <Typography gutterBottom variant="h5" component="h2">
                    {card.title}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
