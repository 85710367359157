import React, {useState} from "react";

import Layout from "../components/Layout";
import Categories from "../components/Categories";
import Title from "../components/Title";

import image3 from "../images/img1.jpg";
import image1 from "../images/newCat.png";

import DynamixFront from "../Garments/Dynamix/DynamixFront";
import {COLORS} from "../utils/colors";
import SoftshelljackePlusFront from "../Garments/SoftshelljackePrimoPlus/SoftshelljackePrimoPlusFront";

export default function Index() {
  const [cards] = useState([
    {
      id: 1,
      img: image3,
      title: "Hosen - Shorts - Latzhosen - Overall",
      link: "/pants",
      preview: (
        <DynamixFront
          lightContrast={COLORS.red.value}
          darkContrast={COLORS.black.value}
          stitching={COLORS.white.value}
          patchColor={COLORS.black.value}
          azomo={COLORS.white.value}
          preview={true}
        />
      ),
    },
    {
      id: 2,
      img: image1,
      title: "Parka – Fleece – Softshell – Mantel - Jacke – Gilet",
      link: "/jackets",
      preview: (
        <SoftshelljackePlusFront
          lightContrast={COLORS.black.value}
          darkContrast={COLORS.red.value}
          stitching={COLORS.white.value}
          preview={true}
        />
      ),
    },
    // { id: 3, img: image3, title: 'Protective work gear', link: '/protective' },
  ]);

  return (
    <Layout>
      <Title />
      <Categories>{cards}</Categories>
    </Layout>
  );
}
