import React, {useEffect} from "react";

import {makeStyles, Container, Typography, Box} from "@material-ui/core";

import "../utils/title.css";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  azomo: {
    maxWidth: "55%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
    },
  },
  subtitle: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: "15%",
      paddingRight: "15%",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0%",
      paddingRight: "0%",
    },
  },
}));

export default function Title() {
  const classes = useStyles();

  useEffect(() => {
    function animateSgv(id, delay, delayIncrement) {
      const logo = document.getElementById(id);
      const logoPaths = document.querySelectorAll(`#${id} path`);
      delay = delay;
      for (let i = 0; i < logoPaths.length; i++) {
        logoPaths[i].style.strokeDasharray = logoPaths[i].getTotalLength();
        logoPaths[i].style.strokeDashoffset = logoPaths[i].getTotalLength();
        logoPaths[i].style.animation = `line-anim 2s ease forwards ${delay}s`;
        delay += delayIncrement;
      }
      logo.style.animation = `fill 5.0s ease forwards ${delay}s`;
    }
    if (document.getElementById("logo")) {
      animateSgv("logo", 0, 0.25);
    }
  }, []);

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="xl">
        <Box align="center">
          <svg
            id="logo"
            fill="none"
            viewBox="0 0 396.581 69.231"
            xmlns="http://www.w3.org/2000/svg"
            className={classes.azomo}
          >
            <g
              id="svgGroup"
              strokeLinecap="round"
              fillRule="evenodd"
              fontSize="9pt"
              stroke="#000000"
              strokeWidth="0.25mm"
            >
              <path
                d="M23.797,45.324h-9.763l-1.894,6.381H4.113l10.71-32.829h4.081v0.023l0.023-0.023h4.081L33.74,51.705h-8.026L23.797,45.324z
		 M15.792,39.461h6.246L18.972,29.27h-0.135L15.792,39.461z"
              />
              <path d="M43.998,45.842h13.528v5.862H34.98v-3.427l13.257-23.539H34.912v-5.862h22.412v3.292L43.998,45.842z" />
              <path
                d="M88.281,38.244c0,4.043-1.282,7.376-3.844,10c-2.563,2.623-5.911,3.935-10.045,3.935c-4.164,0-7.539-1.312-10.124-3.935
		c-2.585-2.623-3.878-5.956-3.878-10v-5.907c0-4.028,1.289-7.358,3.867-9.988c2.578-2.63,5.941-3.946,10.09-3.946
		c4.118,0,7.47,1.315,10.056,3.946s3.878,5.96,3.878,9.988V38.244z M80.682,32.291c0-2.345-0.552-4.269-1.657-5.772
		c-1.104-1.503-2.664-2.254-4.678-2.254c-2.06,0-3.634,0.748-4.724,2.243c-1.09,1.496-1.635,3.424-1.635,5.783v5.953
		c0,2.375,0.552,4.314,1.657,5.817c1.105,1.503,2.687,2.255,4.746,2.255c1.999,0,3.547-0.751,4.645-2.255
		c1.097-1.503,1.646-3.442,1.646-5.817V32.291z"
              />
              <path
                d="M110.265,41.852h0.135l7.418-22.976h10.079v32.829h-7.621V30.556l-0.135-0.022l-7.237,21.171h-5.118l-7.103-20.856
		l-0.135,0.022v20.833h-7.621V18.876h9.989L110.265,41.852z"
              />
              <path
                d="M160.409,38.244c0,4.043-1.282,7.376-3.844,10c-2.563,2.623-5.911,3.935-10.045,3.935c-4.164,0-7.539-1.312-10.124-3.935
		c-2.585-2.623-3.878-5.956-3.878-10v-5.907c0-4.028,1.289-7.358,3.867-9.988c2.578-2.63,5.941-3.946,10.09-3.946
		c4.118,0,7.47,1.315,10.056,3.946s3.878,5.96,3.878,9.988V38.244z M152.81,32.291c0-2.345-0.552-4.269-1.657-5.772
		c-1.104-1.503-2.664-2.254-4.678-2.254c-2.06,0-3.634,0.748-4.724,2.243c-1.09,1.496-1.635,3.424-1.635,5.783v5.953
		c0,2.375,0.552,4.314,1.657,5.817c1.105,1.503,2.687,2.255,4.746,2.255c1.999,0,3.547-0.751,4.645-2.255
		c1.097-1.503,1.646-3.442,1.646-5.817V32.291z"
              />
              <path
                d="M176.552,51.705V18.876h12.017c3.863,0,7.096,1.259,9.695,3.777c2.602,2.518,3.9,5.746,3.9,9.684v5.907
		c0,3.953-1.299,7.185-3.9,9.695c-2.6,2.51-5.832,3.765-9.695,3.765H176.552z M184.173,24.738v21.104h4.396
		c1.729,0,3.154-0.706,4.273-2.119s1.68-3.239,1.68-5.479v-5.953c0-2.209-0.561-4.021-1.68-5.434
		c-1.119-1.413-2.545-2.12-4.273-2.12H184.173z"
              />
              <path d="M225.908,37.658h-11.566v8.185h13.866v5.862h-21.487V18.876h21.51v5.862h-13.889v7.057h11.566V37.658z" />
              <path
                d="M249.424,43.001c0-1.172-0.383-2.07-1.149-2.694c-0.767-0.624-2.157-1.289-4.171-1.996c-4.074-1.322-7.1-2.731-9.075-4.228
		c-1.978-1.496-2.965-3.648-2.965-6.459c0-2.751,1.149-4.975,3.449-6.674s5.224-2.548,8.771-2.548c3.743,0,6.757,0.875,9.042,2.626
		c2.284,1.751,3.389,4.22,3.314,7.407l-0.046,0.135h-7.395c0-1.548-0.422-2.672-1.263-3.371c-0.843-0.699-2.112-1.048-3.811-1.048
		c-1.384,0-2.473,0.338-3.27,1.015c-0.797,0.676-1.195,1.51-1.195,2.502c0,0.962,0.409,1.732,1.229,2.311
		c0.818,0.579,2.348,1.304,4.588,2.176c3.803,1.127,6.682,2.503,8.636,4.126c1.953,1.624,2.931,3.848,2.931,6.674
		c0,2.886-1.127,5.145-3.382,6.775s-5.246,2.446-8.974,2.446c-3.713,0-6.858-0.875-9.436-2.627c-2.578-1.751-3.83-4.505-3.755-8.263
		l0.046-0.135h7.418c0,1.909,0.469,3.266,1.409,4.069c0.938,0.805,2.378,1.207,4.317,1.207c1.593,0,2.78-0.316,3.563-0.947
		C249.033,44.85,249.424,44.024,249.424,43.001z"
              />
              <path d="M269.265,51.705h-7.599V18.876h7.599V51.705z" />
              <path
                d="M300.899,47.173c-1.143,1.383-2.751,2.563-4.825,3.54c-2.074,0.978-4.743,1.466-8.005,1.466
		c-4.073,0-7.38-1.248-9.92-3.743c-2.541-2.495-3.811-5.734-3.811-9.718v-6.854c0-3.953,1.236-7.185,3.709-9.695
		c2.473-2.51,5.655-3.765,9.549-3.765c4.193,0,7.361,0.936,9.503,2.807c2.143,1.871,3.176,4.43,3.101,7.677l-0.045,0.135h-7.147
		c0-1.608-0.429-2.803-1.285-3.585c-0.857-0.782-2.15-1.172-3.878-1.172c-1.774,0-3.202,0.699-4.284,2.097s-1.624,3.217-1.624,5.457
		v6.899c0,2.285,0.538,4.123,1.612,5.513c1.075,1.39,2.582,2.085,4.521,2.085c1.398,0,2.499-0.113,3.304-0.338
		c0.804-0.226,1.439-0.526,1.905-0.902v-5.862h-5.817v-5.05h13.438V47.173z"
              />
              <path
                d="M332.848,51.705h-7.598l-12.018-20.653l-0.136,0.045v20.608h-7.621V18.876h7.621l12.018,20.631l0.136-0.045V18.876h7.598
		V51.705z"
              />
              <path d="M357.266,37.658H345.7v8.185h13.866v5.862h-21.487V18.876h21.51v5.862H345.7v7.057h11.566V37.658z" />
              <path
                d="M371.472,39.123v12.581h-7.621V18.876h11.432c3.803,0,6.816,0.879,9.041,2.638s3.337,4.164,3.337,7.215
		c0,1.699-0.447,3.157-1.341,4.375c-0.895,1.217-2.214,2.209-3.957,2.976c2.014,0.571,3.461,1.522,4.34,2.852
		c0.879,1.331,1.319,2.988,1.319,4.972v2.074c0,0.857,0.127,1.834,0.383,2.931c0.256,1.098,0.699,1.879,1.33,2.345v0.451h-7.868
		c-0.602-0.466-0.992-1.285-1.173-2.458s-0.271-2.277-0.271-3.314v-1.984c0-1.563-0.414-2.758-1.24-3.585
		c-0.827-0.826-2.037-1.24-3.63-1.24H371.472z M371.472,33.261h3.855c1.533,0,2.706-0.35,3.518-1.048
		c0.812-0.699,1.218-1.703,1.218-3.01c0-1.337-0.418-2.416-1.252-3.235s-2.011-1.229-3.528-1.229h-3.811V33.261z"
              />
            </g>
          </svg>
        </Box>
        {/* <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          DESIGNER
        </Typography> */}
        <Typography
          className={classes.subtitle}
          variant="h5"
          align="center"
          color="textSecondary"
          paragraph
        >
          Wählen Sie die gewünschte Kategorie und gestalten Sie Ihre eigene Kollektion.
        </Typography>

        {/* <div className={classes.heroButtons}>
          <Grid container spacing={2} justify="center">
            <Grid item>
              <Button variant="contained" color="primary">
                Main call to action
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" color="primary">
                Secondary action
              </Button>
            </Grid>
          </Grid>
        </div> */}
      </Container>
    </div>
  );
}
